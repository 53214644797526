<template>
  <v-app-bar
    app
    color="mainLightGrey"
    flat
    height="80"
  >
    <div class="d-flex justify-space-between align-center w-100">
      <div class="hide-on-mobile mt-1">
        <v-app-bar-nav-icon
          class="ml-1"
          color="mainDarkBlue"
          @click.stop="handleSidebarStatus"
        ></v-app-bar-nav-icon>
      </div>

      <div class="show-on-mobile mt-1">
        <v-app-bar-nav-icon
          class="ml-1"
          color="mainDarkBlue"
          @click.stop="drawerChange"
        ></v-app-bar-nav-icon>
      </div>
      <div class="d-flex align-center">
        <h4>{{ username }}</h4>
        <v-btn
          class="ml-2"
          color="primary"
          small
          icon
          :loading="logoutLoading"
          @click="logout"
        >
          <v-icon :size="20">
            mdi-logout-variant
          </v-icon>
          <!--        <span class="hidden-sm-and-down">Wyloguj się</span>-->
        </v-btn>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import {logout} from "@/services/authService";

export default {
  name: 'Header',
  data: () => ({
    drawer: null,
    dialog: false,
    sidebarStatus: null,
    interval: null,
    events: [],
    logoutLoading: false,
    pickedEvent: null,
    userInfo: {},
    persistent: false,
  }),

  props: {
    sidebarOpen: Boolean,
    username: String
  },

  mounted() {
    this.sidebarStatus = this.sidebarOpen;
  },

  methods: {
    async logout() {
      try {
        this.logoutLoading = true
        await logout()
      } finally {
        this.logoutLoading = false
      }
    },

    handleSidebarStatus() {
      this.sidebarStatus = !this.sidebarStatus;
      localStorage.setItem('sidebarOpen', this.sidebarStatus.toString());
      this.$emit('handleSidebarStatus', this.sidebarStatus);
    },

    drawerChange() {
      this.drawer = true;
      this.$emit('drawerChange', this.drawer);
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 1264px) {
  .show-on-mobile {
    display: none;
  }
}

@media (max-width: 1263px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>
