export default [
  {
    url: '/courses',
    title: 'Szkolenia',
    icon: 'mdi-school'
  },
  {
    url: '/users',
    title: 'Użytkownicy',
    icon: 'mdi-account'
  },
  {
    url: '/questionnaires',
    title: 'Ankiety',
    icon: 'mdi-format-list-bulleted'
  },
]
