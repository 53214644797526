<template>
  <v-list
    active-class="active-color"
    class="pt-0 sidebar-list"
    dense
  >
    <div class="text-center mb-4 mt-4">
      <img
        alt="logo"
        :height="miniVariant ? 29 : 70"
        src="../../../../public/rt_logo.png"
        srcset="../../../../public/rt_logo.png 1x"
        :width="miniVariant ? 46 : 112"
        :style="miniVariant && 'margin: 20px 0'"
      />
    </div>
    <SidebarItem
      v-for="item in itemsList"
      :key="item.url"
      :url="item.url"
      :icon="item.icon"
      :title="item.title"
    />
  </v-list>
</template>

<script>
import sidebarItems from '@/constants/sidebarItems';
import SidebarItem from "@/components/MainLayoutComponents/Sidebar/SidebarItem";

export default {
  name: 'Sidebar',
  components: {SidebarItem},
  data: () => ({
    loading: false,
    isAdmin: false,
    itemsList: sidebarItems
  }),

  props: {
    miniVariant: Boolean,
  },
};
</script>

<style lang="scss">
.sidebar-list .v-list-item__title {
  height: 20px;
  line-height: 20px !important;
}

.f-17 {
  font-size: 17px !important;
}

.icon-field {
  padding-left: 19px;
}

.sidebar-list {
  .v-list-item {
    max-height: 58px;
  }

  .mini-variant {
    position: relative;
    right: -8px;
  }

  .mini-variant-icons {
    width: 70px;
  }
}
</style>
